(function(w, $) {
    if (!w.sbf) {
        w.sbf = {};
    }

    var EVENT_PREFIX = 'sbf.social_connect.', // all events will be triggered on the window object
        fb_app_id,
        FB_LOGIN_BUTTON_CLASS = 'facebook-login-link',
        $fb_login_buttons,
        facebook_process_options = {
            facebook_login: 1, //a standard login: show 'get started' overlay if no facebook user, else go to members
            fast_registration: 2, // I think this is only used on the /register/account/authcodecreate page
            facebook_donate: 3, //for connecting to facebook during donation process
            facebook_create_on_no_user: 4 // for connecting to facebook on participant, event, and kid creation, if no FB user, cruise on
        },
        facebook_process_type = 1; //default to standard login,

    sbf.facebook = {
        login: function() {
            if (facebook_process_type == facebook_process_options.facebook_donate || facebook_process_type == facebook_process_options.fast_registration) {
                $('.donate-signin-text-container').fadeOut(200);
            } else {
                sbf.login.hide_non_sbf_login_content_feature();
            }

            sbf.login.show_message_feature('Connecting to Facebook', 'Loading the connection to your Facebook account in a new window. If you have a pop up blocker, you will need to disable it. If you experience problems, refresh this page and try again.', false);

            FB.login(function(response) {
                if (response.status === 'connected') {
                    sbf.facebook.process_login(response.authResponse);
                } else if (response.status === 'not_authorized') {
                    sbf.facebook.not_found();
                    sbf.facebook.trigger_custom_event('fb_login_no_user_found');
                } else {
                    $('#donate-sbf-login-message-feature-close, #sbf-login-message-feature-close').click();
                }
            }, { scope: 'public_profile,email' });

        },

        process_login: function(r) {
            $.ajax({
                url: '/ajax/socialconnect/fblogin',
                type: 'post',
                dataType: 'json',
                data: {
                    token: r.accessToken,
                    identifier: r.userID
                },
                success: function(response) {
                    if (response.data && response.data.success) {
                        // Successfully logged in

                        sbf.facebook.login_success();
                    } else if (response.error && (response.error.code == 1103 || response.error.code == 1201)) {

                        if(response.error.code == 1103) {
                            // User hasn’t connected Facebook before
                            sbf.facebook.not_found();
                            sbf.facebook.trigger_custom_event('fb_login_no_user_found');
                        } else {
                            sbf.overlay.showQuickMessage('Oops!', 'Your account has been suspended.');
                        }
                    } else {
                        sbf.facebook.login_rejected();
                        sbf.facebook.trigger_custom_event('fb_login_fail');
                    }
                },
                error: function(jq, textStatus, errorThrown) {
                    sbf.overlay.showQuickMessage('Oops!', 'Sorry, we had an issue connecting you to Facebook. Please try again.');
                }
            });
        },

        login_success: function() {
            sbf.facebook.trigger_custom_event('fb_login_success');
            window.location.reload();
        },

        login_rejected: function() {
            sbf.overlay.showQuickMessage('Oops!', 'Sorry, we had an issue connecting you to Facebook. Please try again.');
        },

        not_found: function(this_isnt_me_clicked) {
            if (facebook_process_type == facebook_process_options.facebook_login) {
                if (this_isnt_me_clicked == true) {
                    sbf.login.show_get_started_feature();
                } else {
                    sbf.login.show_no_facebook_account_feature();
                }
            } else if (facebook_process_type == facebook_process_options.facebook_create_on_no_user || facebook_process_type == facebook_process_options.fast_registration) {
                window.location = $('#facebook-login-process-type').attr('data-facebook-login-create-account-url');
            } else if (facebook_process_type == facebook_process_options.facebook_donate) {
                sbf.login.show_message_feature('Oops!', 'Your Facebook account is not currently connected with the St. Baldrick\'s Foundation.  Please donate without logging in.', true);
            }
        },

        init: function() {
            $('#sbf-login-associate-new-account').bind('click', function(click) {
                click.preventDefault();
                sbf.facebook.not_found(true);
            });

            $fb_login_buttons = $('.' + FB_LOGIN_BUTTON_CLASS);
            $fb_login_buttons.bind('click', sbf.facebook.login);

            facebook_process_type = $('#facebook-login-process-type').attr('data-facebook-login-process-type') || facebook_process_type;
        },

        trigger_custom_event: function(name) {
            $(w).trigger(EVENT_PREFIX + name);
        }
    };

    (function init() {
        fb_app_id = $('#facebook-app-id').attr('rel');

        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.appendChild(js);
        }(document, 'script', 'facebook-jssdk'));

        window.fbAsyncInit = function() {
            FB.init({
                appId: fb_app_id,
                cookie: true,
                xfbml: false,
                version: 'v19.0'
            });
            sbf.facebook.init();
        };
    })();
})(window, jQuery);
